import { useEffect } from 'react';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';
import maxBy from 'lodash/maxBy';

import { parsePrice } from './price';
import { sanitizeCustomerData, sanitizeEmail, sanitizePhoneNumber } from './fb-capi';
import { BuyFromType, OrderPaymentMethod } from 'constant';

import { useAdData } from 'utils/ad-data-context';

export const PAGE_TYPES = {
  EDITORIAL_PAGE: 'Editorial Page',
  SALES_PAGE: 'Sales Page',
  CHECKOUT_PAGE: 'Checkout Page',
  UPSELL_PAGE: 'Upsell Page',
  POLICY_PAGE: 'Policy Page',
  ORDER_SUMMARY_PAGE: 'Order Summary Page',
  ARTICLE_PAGE: 'Article Page',
  LISTICLE_PAGE: 'Listicle Page',
  REVIEW_PAGE: 'Review Page',
  HOME_PAGE: 'Home Page',
};

export const PURCHASE_TYPES = {
  MAIN_OFFER: 'Main Offer',
  UPSELL_OFFER: 'Upsell Offer',
};

function hasRudderstack() {
  return (
    process.env.NEXT_PUBLIC_RUDDERSTACK_FEATURE_FLAG === 'true' && typeof window !== 'undefined'
  );
}

export function getLeadId() {
  if (!hasRudderstack()) return;

  const traits = window.rudderanalytics?.getUserTraits?.() || {};

  return traits.velocity_lead_id;
}

/**
 * This function should only be used for firing events imperatively (i.e. as part
 * of a submit handler). If want an event to fire on page load, use `useRudderstackEvents`.
 *
 * Pushes an event or array of events onto the page's dataLayer array.
 * @param {Object/Array} eventOrEvents - An event object or an array of event objects.
 **/
function eventFn(eventOrEvents, { eventType = 'track', eventName } = {}) {
  if (!hasRudderstack()) return;

  window.firedRudderEvents = window.firedRudderEvents || [];

  const events = Array.isArray(eventOrEvents) ? eventOrEvents : [eventOrEvents];

  const eventIdentifier = `${eventType},${eventName}`;

  // Prevent duplicate events from firing
  if (window.firedRudderEvents.includes(eventIdentifier)) return;

  events.forEach(body => {
    window.rudderanalytics?.[eventType]?.(eventName, body);
    window.firedRudderEvents.push(eventIdentifier);
  });
}

export function getAnonymousId() {
  if (!hasRudderstack()) return;

  return window.rudderanalytics?.getAnonymousId();
}

export function getSessionId() {
  if (!hasRudderstack()) return;

  return window.rudderanalytics?.getSessionId();
}

export function getUserId() {
  if (!hasRudderstack()) return;

  return window.rudderanalytics?.getUserId();
}

export function getUserTraits() {
  if (!hasRudderstack()) return;

  return window.rudderanalytics?.getUserTraits();
}

export function identify(body, userId) {
  if (!hasRudderstack()) return;

  if (userId) {
    window.rudderanalytics?.identify(userId, body);
  } else {
    window.rudderanalytics?.identify(undefined, body);
  }
}

export function track(event, body) {
  if (!hasRudderstack()) return;

  if (typeof event === 'string') {
    window.rudderanalytics.track(event, body);
  } else {
    window.rudderanalytics.track(body);
  }
}

// Fires Rudderstack events as part of page load.
function useRudderstackEvents(eventOrEvents, requiredVars, eventOptions) {
  useEffect(() => {
    const isLoaded = !some(requiredVars, isUndefined);
    if (!isLoaded) return;
    eventFn(eventOrEvents, eventOptions);
    // We do not want to provide eventOrEvents as a hook dependency because
    // it will change each time `usePageView` is called since it defines the
    // event data on each call. We are relying on `requiredVars` to contain
    // the necessary hook dependencies.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
}

export function usePageView({ pageType, path, merchantName, funnelData }) {
  const { adData } = useAdData();

  if (pageType && !Object.values(PAGE_TYPES).includes(pageType)) {
    throw new Error(`useRudderPageView: Invalid pageType ${pageType}`);
  }

  const pathWithoutQuerystring = path.split('?')[0];

  const pageViewEvent = {
    ...adData,
    pageType,
    funnelPageId: funnelData?.funnelPageId,
  };

  if (merchantName) {
    pageViewEvent.merchant = merchantName;
  }

  const identifyEvent = {};

  useRudderstackEvents(pageViewEvent, [pathWithoutQuerystring], { eventType: 'page' });
  useRudderstackEvents(identifyEvent, [pathWithoutQuerystring], { eventType: 'identify' });
}

// Uses quantity 1 if not specified on offer (i.e. on page load). Quantity may be specified with
// quantity selector controls.
function getItemValue(item) {
  if (!item) return 0;
  return parsePrice(item.price) * (item.quantity ?? 1);
}

function getHighestValueItem(items) {
  if (!items) return null;
  return maxBy(items, getItemValue);
}

// Determine item with greatest value (quantity * price).
function getHighestValue(items) {
  if (!items) return 0;
  return getItemValue(getHighestValueItem(items));
}

function getTotalValue(items) {
  if (!items) return 0;
  return items.reduce((total, item) => total + parsePrice(item.price) * (item.quantity ?? 1), 0);
}

function buildOfferItem(offers) {
  if (!offers) return [];

  return offers.map(offer => ({
    product_id: offer?.id,
    name: offer?.name,
    // Used to be affiliation?
    category: 'Velocity',
    position: offer?.itemListIndex,
    // item_list_id: offer?.itemListId,
    brand: offer?.store?.brand?.name,
    price: parsePrice(offer?.price),
    quantity: offer.quantity ?? 1,
  }))[0];
}

// Uses quantity 1 if not specified on offer (i.e. on page load). Quantity may be specified with
// quantity selector controls.
function buildOfferItems(offers) {
  if (!offers) return [];

  return offers.map(offer => ({
    product_id: offer?.id,
    name: offer?.name,
    // affiliation: 'Velocity',
    position: offer?.itemListIndex,
    // item_list_id: offer?.itemListId,
    brand: offer?.store?.brand?.name,
    price: parsePrice(offer?.price),
    quantity: offer.quantity ?? 1,
  }));
}

function buildLineItems(lineItems) {
  if (!lineItems) return [];

  return lineItems.map(lineItem => ({
    item_id: lineItem?.offerId,
    item_name: lineItem?.name,
    affiliation: 'Velocity',
    coupon: lineItem?.discountCode,
    discount: parsePrice(lineItem?.discountAmount),
    index: lineItem?.itemListIndex,
    item_list_id: lineItem?.itemListId,
    item_brand: lineItem?.brandName,
    price: parsePrice(lineItem?.price),
    quantity: lineItem?.quantity,
  }));
}

export function useFunnelDetail(offerData) {
  // view_item
  const viewItem = {
    currency: 'USD',
    price: getHighestValue(offerData?.data),
    items: buildOfferItems(offerData?.data),
  };

  // view_item_list
  const viewItemList = {
    list_id: offerData?.data?.[0]?.itemListId,
    products: offerData?.data?.map(offer => ({
      product_id: offer?.id,
      name: offer?.name,
      position: offer?.itemListIndex,
      brand: offer?.store?.brand?.name,
      price: parsePrice(offer?.price),
      quantity: 1,
    })),
  };

  useRudderstackEvents(viewItem, [offerData], { eventName: 'Product Viewed' });
  useRudderstackEvents(viewItemList, [offerData], { eventName: 'Product List Viewed' });
}

// Fired for CTA button clicks in MultiBundle component, offer selection in FunnelCart component,
// or quantity change in CTA component.
// Note: Not fired on initially loaded FunnelCart product selection as this event represents user interation.
export function onSelectItem(offer) {
  return track('Product Clicked', buildOfferItem([offer]));
}

// Fired for buy from selection in FunnelCart component.
// TODO: Don't think we have an equivilant event
export function onSelectBuyFrom(offer, buyFromTarget) {
  const price = buyFromTarget === BuyFromType.AMAZON ? offer?.amazonPrice : offer?.price;

  track('Buy From Selected', {
    ecommerce: {
      currency: offer?.store?.currency,
      value: getHighestValue([{ ...offer, price }]),
      buy_from: buyFromTarget,
      items: buildOfferItems([{ ...offer, price }]),
    },
  });
}

// Should be fired on sales page anchor link clicks to go to CTA buttons (i.e. StickyBanner)
export function onAddToCart(offerData) {
  const highestValueItem = getHighestValueItem(offerData?.data);

  return track('CTA Anchor Clicked', buildOfferItem([highestValueItem]));
}

// Should be fired for any sales page CTA clicks. This includes: links to checkout page,
// sticky buy button express pay options. This is not used for external buy buttons (i.e. amazon).
export function onBeginCheckout(offers, paymentMethod = OrderPaymentMethod.CREDIT_CARD) {
  return track('Checkout Started', {
    currency: offers[0]?.store?.currency,
    value: getTotalValue(offers),
    // Non-standard, maybe affiliation ?
    buy_from: BuyFromType.VELOCITY,
    // Non-standard
    payment_method: paymentMethod,
    products: buildOfferItems(offers),
  });
}

// Should be fired for any sales page external CTA click (i.e. Amazon)
export function onExternalCheckout(offer, buyFromTarget, price) {
  return track('Checkout Started', {
    currency: offer?.store?.currency,
    value: getHighestValue([{ ...offer, price }]),
    buy_from: buyFromTarget,
    products: buildOfferItems([{ ...offer, price }]),
  });
}

// Should be fired when user identified with email in checkout form, or on express checkout button
// click (including sticky buy button express methods)
export function onAddContactInfo({
  cartData,
  paymentMethod = OrderPaymentMethod.CREDIT_CARD,
  checkoutLocation,
  email,
  leadId,
}) {
  const sanitized = sanitizeCustomerData({ email });

  const user_data = {
    email: sanitized.email,
  };

  if (leadId) {
    user_data.velocity_lead_id = leadId;
  }

  track('Contact Info Entered', {
    currency: cartData?.data.currency,
    price: parsePrice(cartData?.data.total),
    coupon: cartData?.data.discountCode,
    payment_method: paymentMethod,
    checkout_location: checkoutLocation,
    products: buildLineItems(cartData?.data.lineItems),
  });
}

// eslint-disable-next-line no-unused-vars
function buildUserData(data) {
  return {
    email: data?.email,
    phone: data?.phone,
    firstName: data?.firstName,
    lastName: data?.lastName,
    address: {
      city: data?.city,
      country: data?.countryCode,
      postalCode: data?.postalCode,
      street: data?.street,
      state: data?.regionCode,
    },
  };
}

// On 2-step checkout: fired when user clicks "continue to payment"
// On 1-step checkout: fired when user clicks "buy now"
export function onAddShippingInfo({
  cartData,
  paymentMethod = OrderPaymentMethod.CREDIT_CARD,
  checkoutLocation,
  checkoutType,
}) {
  track('Shipping Info Entered', {
    ecommerce: {
      currency: cartData?.data.currency,
      price: parsePrice(cartData?.data.total),
      coupon: cartData?.data.discountCode,
      payment_method: paymentMethod,
      checkout_location: checkoutLocation,
      checkout_type: checkoutType,
      items: buildLineItems(cartData?.data.lineItems),
    },
  });
}

export function onAddPaymentInfo({ paymentMethod, cartData, checkoutLocation }) {
  track('Payment Info Entered', {
    ecommerce: {
      currency: cartData?.data.currency,
      value: parsePrice(cartData?.data.total),
      coupon: cartData?.data.discountCode,
      payment_method: paymentMethod,
      checkout_location: checkoutLocation,
      items: buildLineItems(cartData?.data.lineItems),
    },
  });
}

// eslint-disable-next-line no-unused-vars
export function onPurchase({ orderId, cartData, purchaseType, customerData, paymentMethod }) {
  return;
}

// Should be fired when user submit "Save This"
export function onSavePromotion(email, phone, offerData, leadId) {
  const user_data = {
    email: email ? sanitizeEmail(email) : null,
    phone: phone ? sanitizePhoneNumber(phone) : null, // formatted to FB specification
  };

  if (leadId) {
    user_data.velocity_lead_id = leadId;
  }

  identify(user_data, leadId);

  track('Lead Generated', {
    event: 'form_submit',
    form_name: 'Save Promotion',
    form_id: email ? 'email' : 'phone',
    ecommerce: {
      currency: offerData?.data?.[0]?.store?.currency,
      value: getHighestValue(offerData?.data),
    },
  });
}

// Should be fired when user focus's on "Save This"
// eslint-disable-next-line no-unused-vars
export function onFocusPromotion(form_id) {
  return;
}

export function onFooterSignup(email, phone, offerData, leadId) {
  const user_data = {
    email: email ? sanitizeEmail(email) : null,
    phone: phone ? sanitizePhoneNumber(phone) : null, // formatted to FB specification
  };

  if (leadId) {
    user_data.velocity_lead_id = leadId;
  }

  identify(user_data, leadId);

  track('Lead Generated', {
    event: 'form_submit',
    form_name: 'Footer Newsletter Signup',
    form_id: email ? 'email' : 'phone',
    ecommerce: {
      currency: offerData?.data?.[0]?.store?.currency,
      value: getHighestValue(offerData?.data),
    },
  });
}

export function onFocusFooter(form_id) {
  track('Lead Generated', {
    event: 'form_start',
    form_name: 'Footer Newsletter Signup',
    form_id,
  });
}

export function getPageTypeFromCateogry(category) {
  switch (category.toLowerCase()) {
    case 'articles':
    case 'reviews':
      return PAGE_TYPES.EDITORIAL_PAGE;
    case 'the lists':
      return PAGE_TYPES.LISTICLE_PAGE;
  }
}

export function getMerchantTypeFromCateogry(category, merchantName) {
  category = category?.toLowerCase();

  if (category === 'articles' || category === 'reviews') {
    return merchantName;
  }

  return undefined;
}
